<template>
  <div class="sidebar">
    <div class="px-8">
      <logo class="w-28" />
    </div>

    <div class="sidebar-content">
      <router-link :to="{ path: pages.dashboard }" class="item">
        <div class="line"></div>
        <ion-icon name="home" class="icon" />
        Home
      </router-link>

      <router-link :to="{ path: pages.investment }" class="item">
        <div class="line"></div>
        <ion-icon name="briefcase" class="icon" />
        Investments
      </router-link>

      <router-link :to="{ path: pages.account }" class="item">
        <div class="line"></div>
        <ion-icon name="albums" class="icon" />
        My bank accounts
      </router-link>

      <router-link :to="{ path: pages.profile }" class="item">
        <div class="line"></div>
        <ion-icon name="person-circle" class="icon" />
        My account
      </router-link>

      <router-link :to="{ path: pages.change_password }" class="item">
        <div class="line"></div>
        <ion-icon name="settings" class="icon" />
        Change password
      </router-link>
    </div>
  </div>
  <!-- <div class="px-12 -ml-5 absolute top-1/2 sm:w-1/5">
    <Profile />
  </div> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { APP_PAGES } from '@/router/paths';

export default defineComponent({
  // components: {
  //   Profile: defineAsyncComponent(() => import('../account/Profile.vue')),
  // },
  setup: () => {
    return {
      pages: APP_PAGES,
    };
  },
});
</script>
