
import { defineComponent } from 'vue';
import { APP_PAGES } from '@/router/paths';

export default defineComponent({
  // components: {
  //   Profile: defineAsyncComponent(() => import('../account/Profile.vue')),
  // },
  setup: () => {
    return {
      pages: APP_PAGES,
    };
  },
});
